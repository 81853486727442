/* GTFO Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active { 
  font-size: 14px;
  -webkit-transition-delay: 9999s; transition-delay: 9999s; 
}

* { 
  -moz-box-sizing: border-box; 
  -webkit-box-sizing: border-box; 
   box-sizing: border-box; 
}

body {
  font-family: 'Barlow', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4fa;
}

input:focus, button:focus, textarea:focus {
  outline: 0;
}

div {
  display: block;
}